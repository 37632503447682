let stripeKey = document.head.querySelector('meta[name="stripe-key"]')
var stripe = Stripe(stripeKey.content)
// Create an instance of Elements.
var elements = stripe.elements()

// Custom styling can be passed to options when creating an Element.
// (Note that this demo uses a wider set of styles than the guide below.)
// Create an instance of the card Element.
let cardStyle = {
    base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
    },
}

var card = elements.create('card', {
    style: cardStyle
})

document.onreadystatechange = function () {
    card.mount('#card-element')
}

const cardButton = document.getElementById('submit-payment')

if (cardButton) {
    if (document.getElementById('card-element')) {
        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element')

        document.getElementById('submit-payment').disabled = true

        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function (event) {
            var displayError = document.getElementById('card-errors')
            if (event.error) {
                displayError.textContent = event.error.message
            } else {
                displayError.textContent = ''
            }
            if (event.complete) {
                document.getElementById('submit-payment').disabled = false
            } else {
                document.getElementById('submit-payment').disabled = true
            }
        })

        card.addEventListener('complete', function (event) {})

        var form = document.getElementById('payment-form')

        if (document.getElementById('subscribe')) {
            subscribe()
        } else {
            singleCharge()
        }
    }
}

function singleCharge() {
    let cardHolderName = ''

    if (!document.getElementById('signature')) {
        cardHolderName =
            document.getElementById('firstname') +
            ' ' +
            document.getElementById('lastname')
    } else {
        cardHolderName = document.getElementById('signature')
    }
    cardButton.addEventListener('click', async (e) => {
        e.preventDefault()
        document.getElementById('submit-payment').disabled = true
        const { paymentMethod, error } = await stripe.createPaymentMethod(
            'card',
            card,
            {
                billing_details: {
                    name: cardHolderName.value,
                },
            }
        )

        if (error) {
            // Display "error.message" to the user...
        } else {
            // The card has been verified successfully...
            stripeTokenHandler(paymentMethod.id)
        }
    })
}

function subscribe() {
    const clientSecret = cardButton.dataset.secret
    cardButton.addEventListener('click', async (e) => {
        document.getElementById('submit-payment').disabled = true
        document.getElementById('submit-payment').innerHTML =
            '<i class="fa farefresh fa-spin"></i> Processing...'
        const { setupIntent, error } = await stripe.confirmCardSetup(
            clientSecret,
            {
                payment_method: {
                    card: card,
                    billing_details: {
                        name: cardHolderName.value,
                    },
                },
            }
        )

        if (error) {
            // Display "error.message" to the user...
            var errorElement = document.getElementById('card-errors')
            errorElement.textContent = error.message
        } else {
            // The card has been verified successfully...
            stripeTokenHandler(setupIntent.payment_method)
            e.submit()
        }
    })
}

// Submit the form with the token ID.
function stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = document.getElementById('payment-form')
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripeToken')
    hiddenInput.setAttribute('value', token)
    form.appendChild(hiddenInput)

    // Submit the form
    form.submit()
}
